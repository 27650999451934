import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'
import moment from 'moment'
import Notification from '@/modules/common/services/notification.service'
import { DataTableHeader } from 'vuetify'

interface IScanBreakdown {
  company: string;
  scans: number;
}

interface ILeaderboardData {
  scans: number;
  first_name: string;
  last_name: string;
  email: string;
}

type TotalOrUnique = 'total' | 'unique'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrLeadsReportsView',
  computed: {
    ...mapState('event', ['event']),
    ...mapState('leads', ['leads', 'leadsStats', 'exhibitorStats', 'leaderboard', 'attendee_lead_counts']),
    ...mapState('email', ['emails'])
  }
})
export default class GtrLeadsReportsView extends GtrSuper {
  leads!: Array<any>
  attendee_lead_counts!: Array<any>
  exhibitorStats!: Array<any>
  leaderboard!: any;
  reportExporting: string | null = null
  exporting = false

  data () {
    return {
      loading: false,
      chartFilters: {
        date: {
          from: '',
          from_menu: false,
          to: '',
          to_menu: false
        }
      },
      areaChartOptions: {
        chartArea: {
          width: '85%'
        },
        legend: {
          position: 'top'
        },
        colors: ['#006CBB'],
        vAxis: {
          format: 0,
          viewWindow: {
            min: null,
            max: null
          }
        }
      },
      table: {
        search: '',
        tab: 0,
        headers: [
          {
            text: 'Company',
            align: 'start',
            sortable: true,
            value: 'all'
          },
          {
            text: 'Total Scans',
            align: 'start',
            sortable: true,
            value: 'total'
          },
          {
            text: 'Unique Scans',
            align: 'start',
            sortable: true,
            value: 'unique'
          },
          {
            text: 'Mobile App',
            align: 'start',
            sortable: true,
            value: 'scans_mobile_app'
          },
          {
            text: 'Proscanner',
            align: 'start',
            sortable: false,
            searchable: false,
            value: 'scans_pro'
          }
        ],
        items: []
      },
      event_uuid: this.$route.params.event_uuid,
      to_name: '',
      to: '',
      cc: '',
      bcc: '',
      email_name: 'your_leads',
      showEmailModal: false,
      graphTab: 0,
      pieChartTotalScansOptions: {
        responsive: true,
        hoverBorderWidth: 20,
        title: 'Total Scans',
        width: this.pieChartWidth,
        height: 600,
        pieHole: 0.4
      },
      pieChartTotalScansData: [
        ['Exhibitor', 'Scans']
      ],
      pieChartUniqueScansOptions: {
        responsive: true,
        hoverBorderWidth: 20,
        title: 'Unique Scans',
        width: this.pieChartWidth,
        height: 600,
        pieHole: 0.4
      },
      pieChartUniqueScansData: [
        ['Exhibitor', 'Scans']
      ],
      showEmailAllModal: false,
      leaderboardDownloadUrl: ''
    }
  }

  scanCategory: TotalOrUnique = 'total'
  companyLeaderboardCategory: TotalOrUnique = 'total'
  attendeeLeaderboardCategory: TotalOrUnique = 'total'

  get totalRadioLabel () {
    return `Total Scans (${this.$data.pieChartTotalScansData.reduce((sum, item) => sum + (parseInt(item[1]) || 0), 0)})`
  }

  get uniqueRadioLabel () {
    return `Unique Scans (${this.$data.pieChartUniqueScansData.reduce((sum, item) => sum + (parseInt(item[1]) || 0), 0)})`
  }

  get donutChartAttributes () {
    const options = {
      responsive: true,
      hoverBorderWidth: 20,
      title: 'Unique Scans',
      height: 600,
      pieHole: 0.4
    }
    if (this.scanCategory === 'total') {
      options.title = 'Total Scans'
      return {
        data: this.$data.pieChartTotalScansData,
        options
      }
    } else if (this.scanCategory === 'unique') {
      options.title = 'Unique Scans'
      return {
        data: this.$data.pieChartUniqueScansData,
        options
      }
    } else {
      options.title = 'This should never happen'
      return {
        data: [],
        options
      }
    }
  }

  // headers for the scan breakdown table, which don't display, but apparently are needed for the table to work.
  scanBreakdownHeaders: DataTableHeader<IScanBreakdown>[] = [
    {
      text: 'Company',
      value: 'company'
    }, {
      text: 'Scans',
      value: 'scans'
    }
  ]

  // the first row of the data is the header, so we slice it off.
  get trueScanData (): (string | number)[][] {
    return this.donutChartAttributes.data.slice(1)
  }

  // convert data into a format that the table component can use.
  get scanBreakdownData (): IScanBreakdown[] {
    return this.trueScanData
      .map((item: (string | number)[]) => {
        return {
          company: item[0] as string,
          scans: item[1] as number
        }
      })
  }

  // calculate the sum of all scans in the chart data.
  get sumOfScans (): number {
    return this.trueScanData
      .map((item: (string | number)[]) => item[1] as number)
      .reduce((a: number, b: number) => a + b, 0)
  }

  get attendeeLeaderboard () {
    return this.attendee_lead_counts
      .map<ILeaderboardData>(lead => {
        return {
          scans: lead.total,
          first_name: lead.first_name,
          last_name: lead.last_name,
          email: lead.email
        }
      })
      .sort((a, b) => b.scans - a.scans)
  }

  get companyLeaderboard () {
    if (this.companyLeaderboardCategory === 'total') {
      return []
    } else {
      return []
    }
  }

  async mounted () {
    this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
    this.getLeaderboard('json')
    this.getLeaderboard('xlsx', true)

    const promises: Promise<any>[] = [this.fetchLeads(), this.fetchAttendeeLeads(), this.fetchLeadsStats(), this.fetchExhibitorStats()]
    await Promise.all(promises)

    if (this.leads) {
      const leads = JSON.parse(JSON.stringify(this.leads))

      for (const lead of leads) {
        const scanLabel = lead?.company ? `${lead.company} - ${lead.first_name} ${lead.last_name}` : `${lead.first_name} ${lead.last_name}`
        this.$data.pieChartTotalScansData.push([
          scanLabel,
          lead.total
        ])
        this.$data.pieChartUniqueScansData.push([
          scanLabel,
          lead.unique
        ])
        lead.all = JSON.stringify(lead)

        for (const exhibitor of this.exhibitorStats) {
          if (exhibitor.uuid === lead.uuid) {
            lead.proscanners = exhibitor.ProScanner
            lead.mobileApps = exhibitor['Lead Retrieval Mobile App']
          }
        }
      }

      this.$data.table.items = leads
    }
  }

  handleEmailAllLeadsOpen () {
    this.$data.showEmailAllModal = true
  }

  handleEmailAllLeadsClose () {
    this.$data.showEmailAllModal = false
  }

  private async emailAllLeads () {
    try {
      await this.$store.dispatch('email/emailAllLeads', this.$route.params.event_uuid)
      Container.get(Notification).success('All leads have been emailed successfully.')
    } catch (e) {
      Container.get(Notification).error(e)
    } finally {
      this.handleEmailAllLeadsClose()
    }
  }

  get leaderboardHeaders () {
    return ((this as any)?.leaderboard?.headings || []).map(heading => {
      const headingFormatted = heading.replaceAll(' ', '_').toLowerCase()
      return {
        text: heading,
        value: headingFormatted,
        sortable: false
      }
    })
  }

  get leaderboardHeaderKeys () {
    return this.leaderboardHeaders.map(header => header.value)
  }

  get leaderboardItems () {
    const items: any = []
    const reportResult = ((this as any)?.leaderboard?.reportResult || [])
    for (let i = 0; i < reportResult.length; i++) {
      items.push({
        scans: reportResult[i][0],
        first_name: reportResult[i][1],
        last_name: reportResult[i][2],
        email: reportResult[i][3]
      })
    }
    items.sort((a, b) => b.scans - a.scans || a.last_name.localeCompare(b.last_name))
    return items.slice(0, 25)
  }

  get pieChartWidth () {
    switch (this.$vuetify.breakpoint.name) {
      case 'md':
        return 600
      case 'sm':
        return 400
      default:
        return 800
    }
  }

  get minScanChartDateFrom () {
    if (!this.$store.state.leads.leadsStats || !Object.keys((this.$store.state.leads.leadsStats?.total_scans_by_date || {})).length) {
      return moment().format('YYYY-MM-DD')
    }
    const regCounts = this.$store.state.leads.leadsStats.total_scans_by_date
    const regCountsSorted = new Map()
    Object.keys(regCounts).sort((a, b) => (moment(a).toDate() as any) - (moment(b).toDate() as any)).forEach((key) => {
      regCountsSorted.set(key, regCounts[key])
    })
    return moment([...regCountsSorted][0][0]).format('YYYY-MM-DD')
  }

  get maxScanChartDateFrom () {
    if (!this.$store.state.leads.leadsStats || !Object.keys((this.$store.state.leads.leadsStats?.total_scans_by_date || {})).length) {
      return moment().format('YYYY-MM-DD')
    }
    if (this.$data.chartFilters.date.to) {
      return moment(this.$data.chartFilters.date.to).format('YYYY-MM-DD')
    }
    const regCounts = this.$store.state.leads.leadsStats.total_scans_by_date
    const regCountsSorted = new Map()
    Object.keys(regCounts).sort((a, b) => (moment(a).toDate() as any) - (moment(b).toDate() as any)).forEach((key) => {
      regCountsSorted.set(key, regCounts[key])
    })
    return moment([...regCountsSorted][regCountsSorted.size - 1][0]).format('YYYY-MM-DD')
  }

  get minScanChartDateTo () {
    if (!this.$store.state.leads.leadsStats || !Object.keys((this.$store.state.leads.leadsStats?.total_scans_by_date || {})).length) {
      return moment().format('YYYY-MM-DD')
    }
    if (this.$data.chartFilters.date.from) {
      return moment(this.$data.chartFilters.date.from).format('YYYY-MM-DD')
    }
    const regCounts = this.$store.state.leads.leadsStats.total_scans_by_date
    const regCountsSorted = new Map()
    Object.keys(regCounts).sort((a, b) => (moment(a).toDate() as any) - (moment(b).toDate() as any)).forEach((key) => {
      regCountsSorted.set(key, regCounts[key])
    })
    return moment([...regCountsSorted][0][0]).format('YYYY-MM-DD')
  }

  get maxScanChartDateTo () {
    if (!this.$store.state.leads.leadsStats || !Object.keys((this.$store.state.leads.leadsStats?.total_scans_by_date || {})).length) {
      return moment().format('YYYY-MM-DD')
    }
    const regCounts = this.$store.state.leads.leadsStats.total_scans_by_date
    const regCountsSorted = new Map()
    Object.keys(regCounts).sort((a, b) => (moment(a).toDate() as any) - (moment(b).toDate() as any)).forEach((key) => {
      regCountsSorted.set(key, regCounts[key])
    })
    return moment([...regCountsSorted][regCountsSorted.size - 1][0]).format('YYYY-MM-DD')
  }

  get areaChartData () {
    if (!this.$store.state.leads.leadsStats || !Object.keys((this.$store.state.leads.leadsStats?.total_scans_by_date || {})).length) {
      return [['Date', 'Total Scans'], [moment().format('MM/DD/YYYY'), 0]]
    }
    const regCounts = this.$store.state.leads.leadsStats.total_scans_by_date
    const regCountsSorted = new Map()
    Object.keys(regCounts).sort((a, b) => (moment(a).toDate() as any) - (moment(b).toDate() as any)).forEach((key) => {
      regCountsSorted.set(key, regCounts[key])
    })
    const areaChart = [['Date', 'Total Scans']]
    for (const [date, scans] of regCountsSorted) {
      if (date === '' || date === null) {
        areaChart.push([
          date,
          scans
        ])
      } else {
        if (this.$data.chartFilters.date.from && moment(this.$data.chartFilters.date.from) > moment(date)) {
          continue
        }
        if (this.$data.chartFilters.date.to && moment(this.$data.chartFilters.date.to) < moment(date)) {
          continue
        }
        areaChart.push([
          moment(date).format('MM-DD-YYYY'),
          scans
        ])
      }
    }
    /**
     * If the date range is small and 0 scans are recorded during the filter timefram, return to and from with 0 scans to fill the chart
     * Otherwise, we get a chart error
     */
    if (areaChart.length === 1) {
      return [...areaChart, [moment(this.$data.chartFilters.date.from).format('MM/DD/YYYY'), 0], [moment(this.$data.chartFilters.date.to).format('MM/DD/YYYY'), 0]]
    }
    return areaChart
  }

  // If there are no scans yet, we alter areaChartOptions to eliminate negatives from the Y axis
  @Watch('areaChartData')
  onAreaChartDataChange (data: any[]) {
    if (data && Array.isArray(data)) {
      if (data.length === 2) {
        if (data[1][0] === moment().format('MM/DD/YYYY') && data[1][1] === 0) {
          this.$data.areaChartOptions.vAxis.viewWindow.min = 0
          this.$data.areaChartOptions.vAxis.viewWindow.max = 1
        }
      }
    }
  }

  get tableItems () {
    switch (this.$data.table.tab) {
      case 1:
        return this.$data.table.items.filter((item) => item.mobileApps > 0)
      case 2:
        return this.$data.table.items.filter((item) => item.proscanners > 0)
      default:
        return this.$data.table.items
    }
  }

  private async fetchLeadsStats () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchLeadsStats', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async getLeaderboard (file_type: string, download?) {
    try {
      const response = await this.$store.dispatch('leads/getLeaderboard', {
        event_uuid: this.$data.event_uuid,
        file_type,
        download
      })
      if (file_type === 'xlsx') {
        this.$data.leaderboardDownloadUrl = response.data
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchExhibitorStats () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchExhibitorStats', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportAttendeeBootsScans () {
    try {
      this.reportExporting = 'Attendee Booth Scans'
      this.exporting = true
      const response = await this.$store.dispatch('leads/getExportAttendeeBoothScans', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.exporting = false
      this.reportExporting = null
    }
  }

  openEmailModal (name: string, email: string, uuid: string) {
    this.$data.to_name = name
    this.$data.to = email
    this.$data.registration_uuid = uuid
    this.$data.showEmailModal = true
  }

  closeEmailModal () {
    this.$data.to_name = ''
    this.$data.to = ''
    this.$data.registration_uuid = ''
    this.$data.showEmailModal = false
    this.$emit('close')
  }

  async sendLeadsEmail () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        company_uuid: this.$route.params.event_uuid,
        registration_uuid: this.$data.registration_uuid,
        email_name: this.$data.email_name,
        data: {
          event_uuid: this.$route.params.event_uuid,
          company_uuid: this.$route.params.event_uuid,
          registration_uuid: this.$data.registration_uuid,
          email_name: this.$data.email_name,
          to: this.$data.to != null ? this.$data.to.split(',') : '',
          cc: this.$data.cc != null ? this.$data.cc.split(',') : '',
          bcc: this.$data.bcc != null ? this.$data.bcc.split(',') : ''
        }
      }
      await this.$store.dispatch('email/sendEmailToOneRegistration', payload)
      Container.get(Notification).success('Leads email successfully sent.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.closeEmailModal()
    }
  }

  get leadsEmail () {
    const emails = this.$store.state.email.emails
    let leadsEmail = null
    if (emails) {
      emails.forEach(email => {
        if (email.key === 'your_leads') {
          leadsEmail = email
        }
      })
    }
    return leadsEmail
  }

  async exportLeadsForExhibitor (item) {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportLeadsForExhibitor', {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: item.uuid
      })
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportAllLeadsSpreadsheets () {
    try {
      this.reportExporting = 'All Leads Spreadsheets'
      this.exporting = true
      const response = await this.$store.dispatch('leads/getExportAllLeadsSpreadSheets', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.exporting = false
      this.reportExporting = null
    }
  }

  async getExportAllLeadsCounts () {
    try {
      this.reportExporting = 'All Leads Counts'
      this.exporting = true
      const response = await this.$store.dispatch('leads/getExportAllLeadsCounts', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.exporting = false
      this.reportExporting = null
    }
  }

  async exportActivationCodesByExhibitor () {
    try {
      this.reportExporting = 'Activation Codes By Exhibitor'
      this.exporting = true
      const response = await this.$store.dispatch('leads/exportActivationCodesByExhibitor', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.exporting = false
      this.reportExporting = null
    }
  }

  private async fetchLeads () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchLeads', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendeeLeads () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchAttendeeLeads', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
